export const menuIdeasDetailData = [
  {
    id: 0,
    title: "Creamy Sensation Dip",
    ingredients: [
      {
        ingredient: "1/2 cup sliced green onion"
      },
      {
        ingredient: "2 teaspoons curry powder"
      },
      {
        ingredient: "4 tablespoons butter or margarine"
      },
      {
        ingredient: "2 cups sour cream"
      },
    ],
    description: "In saucepan, cook onion and curry in butter until onion is tender. Remove from heat and cool 5 minutes. Fold into sour cream."
  },
  {
    id: 1,
    title: "Sour Cream and Chives Dip",
    ingredients: [
      {
        ingredient: "1/2 cup mayonnaise"
      },
      {
        ingredient: "1/2 cup sour cream"
      },
      {
        ingredient: "1 1/2 teaspoons chives"
      },
      {
        ingredient: "Pinch of garlic salt"
      },
    ],
    description: "Mix all together and chill in refrigerator for 30 minutes before serving."
  },
  {
    id: 2,
    title: "Molasses Mustard Dip",
    ingredients: [
      {
        ingredient: "1 cup molasses"
      },
      {
        ingredient: "4 tablespoons cider vinegar"
      },
      {
        ingredient: "2 teaspoons dry mustard"
      },
      {
        ingredient: "Dash of ginger"
      },
      {
        ingredient: "Salt and pepper to taste"
      },
    ],
    description: "Mix all ingredients well."
  },
  {
    id: 3,
    title: "Creamy Oregano Dip",
    ingredients: [
      {
        ingredient: "1/2 cup mayonnaise"
      },
      {
        ingredient: "1/2 cup non-fat plain yogurt"
      },
      {
        ingredient: "1 clove garlic, crushed"
      },
      {
        ingredient: "1/4 teaspoon crushed oregano"
      },
      {
        ingredient: "1/4 teaspoon salt"
      },
      {
        ingredient: "1/8 teaspoon ground black pepper"
      },
    ],
    description: "Whisk mayonnaise and yogurt until smooth in a small bowl. Stir in garlic, oregano, salt, and pepper; mix well. Cover, refrigerate for 1 hour before serving."
  },
  {
    id: 4,
    title: "Chicken Fundue",
    ingredients: [
      {
        ingredient: "1 jar (16 oz.) pasteurized processed cheese spread"
      },
      {
        ingredient: "1 can (3 oz.) chopped green chili peppers (optional)"
      },
      {
        ingredient: "1 teaspoon prepared mustard"
      },
      {
        ingredient: "1/2 teaspoon hot pepper sauce"
      },
    ],
    description: "Combine ingredients and heat in saucepan or combine sauce ingredients in a microwave-safe bowl and cook in microwave oven on high power for 2 minutes; stir to blend."
  },
  {
    id: 5,
    title: "Dill Dip",
    ingredients: [
      {
        ingredient: "1/2 cup mayonnaise"
      },
      {
        ingredient: "1/2 cup sour cream"
      },
      {
        ingredient: "3/4 teaspoon dried dill weed"
      },
      {
        ingredient: "2 tablespoon minced dill pickle"
      },
      {
        ingredient: "Pinch of garlic salt"
      },
    ],
    description: "Combine ingredients. Mix well. Refrigerate for 1 hour."
  },
  {
    id: 6,
    title: "Santa Fe Sauce",
    ingredients: [
      {
        ingredient: "2 1/3 cups mild thick and chunky salsa"
      },
      {
        ingredient: "1 2/3 cups ranch dressing"
      },
      {
        ingredient: "1 1/2 Tablespoon lime juice"
      },
    ],
    description: "Combine ingredients. Mix well."
  },
  {
    id: 7,
    title: "Honey of a Barbecue Sauce",
    ingredients: [
      {
        ingredient: "2/3 cup barbecue sauce"
      },
      {
        ingredient: "1/3 cup honey"
      },
    ],
    description: "Combine barbecue sauce and honey."
  },
  {
    id: 8,
    title: "Golden Glow",
    ingredients: [
      {
        ingredient: "1 jar (10 oz.) apricot or pineapple jam"
      },
      {
        ingredient: "1 to 2 tablespoons Dijon-style mustard"
      },
    ],
    description: "Combine jam and mustard. Serve with <a href=\"/products/chicken-breast-tenders/\"><b>Chicken Breast Tenders</b></a>."
  },
  {
    id: 9,
    title: "Spring Green",
    ingredients: [
      {
        ingredient: "1 cup sour cream"
      },
      {
        ingredient: "1/3 cup minced green onion"
      },
      {
        ingredient: "1/3 cup minced parsley"
      },
      {
        ingredient: "1/3 cup minced spinach"
      },
      {
        ingredient: "2 teaspoons Worcestershire sauce"
      },
      {
        ingredient: "1 teaspoon prepared horseradish"
      },
    ],
    description: "Combine ingredients in a food processor or blender."
  },
  {
    id: 10,
    title: "Berry Wonderful Dip",
    ingredients: [
      {
        ingredient: "1 can (8oz.) jellied cranberry sauce"
      },
      {
        ingredient: "1/2 cup chili sauce"
      },
      {
        ingredient: "1 tablespoon brown sugar"
      },
      {
        ingredient: "1 tablespoon lemon juice"
      },
      {
        ingredient: "1/4 teaspoon hot pepper sauce"
      },
    ],
    description: "In a saucepan, combine all ingredients. Bring to a boil over medium heat; stir to blend. Simmer gently 3 to 5 minutes."
  },
  {
    id: 11,
    title: "Cream Fettuccini",
    ingredients: [
      {
        ingredient: "1 (8oz.) package cream cheese"
      },
      {
        ingredient: "3/4 cup grated Parmesan cheese"
      },
      {
        ingredient: "1/2 cup butter"
      },
      {
        ingredient: "1/2 cup milk"
      },
      {
        ingredient: "8 ounces of fettuccini, cooked"
      },
    ],
    description: "Mix cream cheese, Parmesan, butter, and milk in a large saucepan. Stir over low heat until smooth. Add fettuccini; toss lightly."
  },
  {
    id: 12,
    title: "Baked Mac 'n Cheese",
    ingredients: [
      {
        ingredient: "2 teaspoons butter"
      },
      {
        ingredient: "1/2 teaspoon salt"
      },
      {
        ingredient: "1/4 teaspoon pepper"
      },
      {
        ingredient: "3 cups milk"
      },
      {
        ingredient: "1/2 cup all-purpose flour"
      },
      {
        ingredient: "1/2 teaspoon dry mustard"
      },
      {
        ingredient: "1 1/2 cup cheddar cheese, grated"
      },
      {
        ingredient: "5 cups cooked elbow macaroni"
      },
    ],
    description: "In sauce pan, melt butter. Add flour, mustard, salt, and pepper. Cook 1 minute, stirring. Gradually stir in milk. Cook over medium heat until thick, 10 minutes, stirring. Stir in 1 cup cheese until melted. Place cooked elbow macaroni in two-quart dish, pour sauce over macaroni, and sprinkle with remaining cheese. Cover and bake at 350°F for 30 minutes."
  },
  {
    id: 13,
    title: "Layered 24 Hour Salad",
    ingredients: [
      {
        ingredient: "1 (10 oz.) package of frozen peas"
      },
      {
        ingredient: "1/2 head lettuce, cut up"
      },
      {
        ingredient: "1/2 cup chopped green peppers"
      },
      {
        ingredient: "1 1/2 cup mayonnaise"
      },
      {
        ingredient: "1/2 cup Parmesan cheese"
      },
      {
        ingredient: "1/2 lb. bacon, diced"
      },
      {
        ingredient: "1/2 cup chopped celery"
      },
      {
        ingredient: "1/2 cup chopped onion"
      },
      {
        ingredient: "1 tablespoon sugar"
      },
      {
        ingredient: "1/2 cup cheese, shredded"
      },
    ],
    description: "Cook peas till tender; drain. Fry bacon till crisp and drain. Layer lettuce, peas, celery, pepper, and onion in large bowl. Mix sugar and mayonnaise; pour over top. Sprinkle with cheese and bacon. Cover and chill overnight."
  },
  {
    id: 14,
    title: "Potatoes au Gratin",
    ingredients: [
      {
        ingredient: "32 oz. package of frozen hash browns, thawed"
      },
      {
        ingredient: "2 cans cream of chicken soup"
      },
      {
        ingredient: "8 ounces grated cheddar cheese"
      },
      {
        ingredient: "8 oz. package of sour cream"
      },
    ],
    description: "Mix all ingredients in a greased baking dish. Bake at 350°F for 30 minutes."
  }
];