import React from "react";
import "./menuIdeasDetail.scss";
import Helmet from "react-helmet";
import Layout from '../components/Layout/Layout';
import WaverChickenGrid from '../components/Grid/WaverChickenGrid';
import { menuIdeasDetailData as data } from '../data/menuIdeasDetail';

const RecipeDetails = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;

  return (
    <Layout
      metaTitle="Our Sausage and Meatball Products"
      metaDescription="Browse our small-batch chicken sausages and meatballs. They're made with all-natural ingredients and combined in deliciously unexpected ways."
      searchTitle="Products"
    >
      <Helmet
      />
      <WaverChickenGrid>
        <div className="recipeDetail">
          <p className="title">{data[i].title}</p>

          <ul className="ingredients">
            {
              data[i].ingredients.map(({ingredient}) => (
                <li key={ingredient}>{ingredient}</li>
              ))
            }
          </ul>

          <p
            className="description"
            dangerouslySetInnerHTML={{ __html: data[i].description }}
          />
        </div>
      </WaverChickenGrid>
    </Layout>
  );
}

export default RecipeDetails;
